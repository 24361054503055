import axios from "axios";

const autoRefreshInterval = 4 * 60 * 1000;
let autoRefreshIntervalId;

export function getToken() {
  const idTokenKey = findLocalStorageKey("idToken");
  const refreshTokenKey = findLocalStorageKey("refreshToken");
  const idToken = idTokenKey ? localStorage.getItem(idTokenKey) : null;

  if (idToken) {
    const { exp } = decodeIdToken(idToken);
    const currentTime = Date.now() / 1000;

    if (exp < currentTime) {
      return refreshIdToken(refreshTokenKey, idTokenKey);
    }

    if (!autoRefreshIntervalId) {

      autoRefreshIntervalId = setInterval(() => refreshIdToken(refreshTokenKey, idTokenKey), autoRefreshInterval);
    }

    return {
      headers: { Authorization: `Bearer ${idToken}` },
    };
  }

  return null;
}

function findLocalStorageKey(suffix) {
  return Object.keys(localStorage).find((key) => key.endsWith(suffix));
}

function decodeIdToken(idToken) {
  const base64Url = idToken.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(atob(base64));
}

async function refreshIdToken(refreshTokenKey, idTokenKey) {
  try {
    const currentRefreshToken = localStorage.getItem(refreshTokenKey);
    const newIdToken = await yourRefreshTokenLogic(currentRefreshToken);
    localStorage.setItem(idTokenKey, newIdToken);
    return newIdToken;
  } catch (error) {
    throw error;
  }
}

async function yourRefreshTokenLogic(refreshToken) {
  const tokenEndpoint = `https://${process.env.REACT_APP_AWS_COGNITO_DOMAIN}.auth.eu-central-1.amazoncognito.com/oauth2/token`;
  const clientId = process.env.REACT_APP_AWS_COGNITO_CLIENT_ID;

  try {
    const response = await axios.post(
      tokenEndpoint,
      `grant_type=refresh_token&refresh_token=${refreshToken}&client_id=${clientId}`,
      { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
    );


    return response.data.id_token;
  } catch (error) {
    console.error("Error refreshing token:", error);
    throw error;
  }
}
