import React, { useState, useContext, useEffect } from "react";

import { GoogleMap, useLoadScript, ControlPosition } from "@react-google-maps/api";
import "./map.css";
import { Icon } from "@abb/abb-common-ux-react";
import { RootContext } from "../../contexts/root-context";
import { MAP_BOUNDRIES } from "../../strings";

export default function Map(props) {

    const { isLeftPaneOpen, totalNotiRecords, relay, drmetrics, selectedTimeTitle, setrelayMetrics, startTS, endTS, mapMarkerErrorMsg } = useContext(RootContext);
    useEffect(() => {

    }, [startTS, relay]);
    const [TURKEY_BOUNDS] = useState(MAP_BOUNDRIES);

    const { setMap, children } = props;
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY
    });

    const renderMap = () => {
        const loadHandler = (map) => {
            setMap(map);
        };
        //Metrics Legends
        const metricLegends = [
            { color: '#E12E2E', label: 'Up to 30 records' },
            { color: '#CC0815', label: 'Up to 20 records' },
            { color: '#A30005', label: 'Up to 10 records' },
            // Add more legends as needed
        ];
        //Metrics Names
        const tripMetric = [
            { metricName: "# Trip Events", metricScore: relay?.trips, metricStat: relay?.tripsChange },
        ];

        const distRecordsMetric = [
            { metricName: "# Disturbance records", metricScore: drmetrics?.drs, metricStat: drmetrics?.drchange },
        ];

        const UnackDRMetric = [
            { metricName: "# Unacknowledged DR", metricScore: drmetrics?.unAckDR, metricStat: drmetrics?.unAckDRChange },
        ];
        const unHealthyRelayMetrics = [
            { metricName: "# Unhealthy Relays", metricScore: relay?.unhealthyRelays, metricStat: relay?.unhealthyRelaysChange },
        ];


        const Metrics = ({ legends }) => {
            return (
                <div className="bedas-metric-box">
                    {legends.map((legend, index) => (
                        <div className="map-metric-container" key={index}>
                            <span className="map-metric-name" >{legend.metricName}</span>
                            <span className={legend.metricStat < 0 ?
                                "metric-green map-metric-score" :
                                legend.metricStat > 0 ? "metric-red map-metric-score" :
                                    "metric-nochange map-metric-score"} >{legend.metricScore}</span>

                            <span className="metric-bottom">
                                <Icon name={legend.metricStat < 0 ? "abb/down-arrow" :
                                    legend.metricStat > 0 ? "abb/up-arrow" : "abb/minus"}
                                    sizeClass="medium" className={legend.metricStat < 0 ?
                                        "metric-green-arrow" :
                                        legend.metricStat > 0 ? "metric-red-arrow" : "metric-nochange"} ></Icon>
                                <span
                                    className={legend.metricStat < 0 ?
                                        "metric-green" :
                                        legend.metricStat > 0 ? "metric-red" : "metric-nochange"}>
                                    {isNaN(Math.abs(legend.metricStat)) ? "" : Math.abs(legend.metricStat)} from {selectedTimeTitle}
                                </span>
                            </span>
                        </div>
                    ))}
                </div>
            );
        };

        return (
            <GoogleMap
                id="circle-example"
                mapContainerStyle={{
                    left: "3%",
                    height: "calc(100% - 160px)",
                    width: "calc(98% - 30px)",
                    position: "fixed",
                    zIndex: -1
                }}
                center={{ lat: 41.0082, lng: 28.9784 }}
                options={{
                    // restriction: {
                    //     latLngBounds: TURKEY_BOUNDS,

                    // },

                    scrollwheel: true,
                    scaleControl: true,
                    mapTypeId: "roadmap",
                    labels: false,
                    zoomControl: true,
                    streetViewControl: false
                }}
                onLoad={loadHandler}
            >
                {children}

                <div className={
                    isLeftPaneOpen == "full" ? "legend-container-full legends-metrics" : "legend-container-thumb legends-metrics"}
                >

                    <Metrics position={3} legends={tripMetric} style={{ bottom: '50px', left: '10%' }} />
                    <Metrics position={3} legends={distRecordsMetric} style={{ bottom: '50px', left: '30%' }} />
                    <Metrics position={3} legends={UnackDRMetric} style={{ bottom: '50px', left: '50%' }} />
                    <Metrics position={3} legends={unHealthyRelayMetrics} style={{ bottom: '50px', left: '70%' }} />

                </div>

            </GoogleMap >
        );
    };

    if (loadError) {
        return <div>Map cannot be loaded right now, sorry.</div>;
    }

    return isLoaded ? renderMap() : <div>{mapMarkerErrorMsg}</div>;

}
