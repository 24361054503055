import React, { useEffect, useContext } from 'react';
import './card.css';
import { Spinner } from '@abb/abb-common-ux-react';
import { RootContext } from "../../contexts/root-context";

function Card(props) {
  const { totalDistRowCounts, totalNotiRecords } = useContext(RootContext);
  const { widgetName, widgetUpdateTime, component, applystyle, applyScroll, widgetCount } = props;

  return (
    <div className='chart-card'>


      <div className='chart-header'>
        <div className='chart-title'>
          {widgetName}

          {widgetName == "Disturbance Records" ? " (" + totalDistRowCounts + ")" : ""}
          {widgetName == "Alarm Notifications" ? " (" + totalNotiRecords + ")" : ""}
        </div>
        {/* <div className='card-update-notification'>
          Last updated  {widgetUpdateTime} mins ago
        </div> */}
      </div>
      <hr />
      {applyScroll ? <div className="card-content card-content-scroll" >
        {component}
      </div> :
        <div className={applystyle ? 'card-content card-content-even-align' : 'card-content'} >
          {component}
        </div>
      }
    </div>

  );
}

export default Card;


