import React, { useEffect, useState } from "react";
import {
  TabItem,
  TabControl,
  Button,
  Datagrid,
  Dialog,
  Spinner,
  Icon,
  WithPopup,
  Popup,
} from "@abb/abb-common-ux-react";
import { useNavigate } from "react-router-dom";
import "./manage-users.css";
import { BASE_URL } from "../../strings";
import EditUser from "./edit-user";
import EditSubstation from "./edit-substation";
import axios from "axios";
import NotificationExample from "../../components/Notification/notification";
import {
  CognitoIdentityProviderClient,
  AdminUserGlobalSignOutCommand,
} from "@aws-sdk/client-cognito-identity-provider"; // Updated import path
import { awsConfig } from "../../components/Authentication/aws-config";
import { getToken } from "../../components/Authentication/get-token";
import { decryptData } from "../../util";

const ManageUsers = () => {
  const [data, setdata] = React.useState([]);
  const [isEdit, setisEdit] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(0);
  const [subData, setSubData] = React.useState([]);
  const [isSubEdit, setisSubEdit] = React.useState(false);
  const [base64String, setBase64String] = useState("");
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isUserDelete, setisUserDelete] = useState(false);
  const [subId, setSubId] = useState("");
  const [isSpin, setispin] = useState(true);
  const [error, setError] = useState(false);
  const [currentUserId, setCurrentUserId] = useState("");
  const [isUploaded, setIsUploaded] = useState(false);
  const navigate = useNavigate();
  const [closeOnEscape, setCloseOnEscape] = React.useState(true);
  const [trigger, setTrigger] = React.useState("hover");
  const [position, setPosition] = React.useState("bottom center right");
  const [isOpen, setIsOpen] = React.useState(false);
  const [isControlledOpen, setIsControlledOpen] = React.useState(false);
  const [rbacManageData, setRbacManageData] = useState([]);

  const usersColumn = React.useMemo(
    () => [
      { fieldKey: "name", title: "NAME" },
      { fieldKey: "email", title: "EMAIL" },
      { fieldKey: "contactNumber", title: "CONTACT NUMBER" },
      { fieldKey: "role", title: "ROLE" },
      { fieldKey: "operationArea", title: "OPERATION AREA" },
      { fieldKey: "status", title: "STATUS" },
      { fieldKey: "action", title: "ACTION" },
    ],
    []
  );
  const [columns, setColumns] = React.useState(usersColumn);
  const [delButton, setDelButton] = React.useState(false);

  const initialSubColumns = React.useMemo(
    () => [
      { fieldKey: "operationArea", title: "OPERATION AREA" },
      { fieldKey: "substationName", title: "SUBSTATION NAME" },
      { fieldKey: "substationCode", title: "SUBSTATION CODE" },
      { fieldKey: "substationType", title: "SUBSTATION TYPE" },
      { fieldKey: "coordinates", title: "LOCATION COORDINATES" },
      { fieldKey: "action", title: "ACTION" },
    ],
    []
  );
  const [subcolumns, setSubColumns] = React.useState(initialSubColumns);
  const [isnotify, setisnotify] = useState("");

  useEffect(() => {
    fetchUserList();
    fetchSubstationData();
  }, []);

  useEffect(() => {
    getButtonDelete();
  }, [subData]);

  const getButtonDelete = () => {
    if (subData.length === 1) {
      setDelButton(true);
    }
  };

  const handleEUserdit = (userId) => {
    // Redirect to the edit page with the user ID
    navigate(`/edit-user/${userId}`);
  };

  const handleUserDelete = async () => {
    // Delete the user from the state
    //setUsers(users.filter((user) => user.id !== userId));
    try {
      const client = new CognitoIdentityProviderClient(awsConfig.awsConfigUserpool);


      const url = BASE_URL + `rbac-delete?id=${currentUserId}`;
      await axios.delete(url, getToken());
      await fetchSubstationData();
      setisUserDelete(false);
      setisnotify("remove-user");

      // Auto refresh after delete user 
      const response1 = await axios.get(
        BASE_URL + `rbac-get`, getToken()
      );
      setRbacManageData(response1.data);
      localStorage.setItem("rbacManagerUsersList", JSON.stringify(response1.data));
      window.location.reload();
      //

    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const fetchUserList = async () => {
    setispin(true);
    try {
      const response = JSON.parse(localStorage.getItem("rbacManagerUsersList"));
      let formatedUserListData = response?.map((userInfo, userInfoIndex) => ({
        fields: {
          name: userInfo?.givenName,
          email: userInfo?.email,
          contactNumber: userInfo?.phoneNumber,
          operationArea:
            userInfo?.operationAreas.length < 2 ? (
              userInfo?.operationAreas
            ) : (
              <WithPopup>
                <span style={{ cursor: "pointer" }}>
                  {" "}
                  {userInfo?.operationAreas}
                </span>
                <Popup
                  trigger={trigger}
                  position={position}
                  closeOnEscape={closeOnEscape}
                  onOpen={() => {
                    setIsOpen(true);
                    setIsControlledOpen(true);
                  }}
                >
                  <div
                    style={{
                      font: "5px",
                      height: "100px",
                      overflowY: "scroll",
                      scrollbarWidth: "2px",
                    }}
                  >
                    {userInfo?.operationAreas.map((item, index) => {
                      return (
                        <p key={index}>
                          <span>{item}</span>
                        </p>
                      );
                    })}
                  </div>
                </Popup>
              </WithPopup>
            ),
          status: userInfo?.activeStatus.toString() == "true" ? "Enabled" : "",
          role: userInfo?.role,
          action: (
            <>
              <Button
                type="discreet-blue"
                onClick={() => {
                  handleEUserdit(userInfo.email);
                }}
                text="Edit"
                shape={"pill"}
              />

              <Button
                onClick={() => {
                  setCurrentUserId(userInfo.email);
                  setisUserDelete(true);
                }}
                disabled={
                  userInfo.email === decryptData(localStorage.getItem("logged_in_user"))?.replace(/"/g, '')
                }
                type="discreet-blue"
                text="Remove"
                shape={"pill"}
              />
            </>
          ),
        },
      }));
      setdata(formatedUserListData);
      setispin(false);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status >= 400 && status < 500) {
          setispin(false);
          setError(true);
        } else if (status >= 500 && status < 600) {
          setispin(false);
          setError(true);
        } else {
          setispin(false);
          setError(true);
        }
      } else {
        setError(true);
        setispin(false);
      }
    } finally {

    }
  };

  const openDeleteDialog = async (id) => {
    setIsDeleteDialogOpen(true);
    setSubId(id);
  };

  const handleSubstationDelete = async () => {
    const url = BASE_URL + `substation-details-delete?substationId=${subId}`;
    await axios.delete(url, getToken());
    setisnotify("sub-delete");
    await fetchSubstationData();
    setIsDeleteDialogOpen(false);
    setisnotify("");
  };
  const fetchSubstationData = async () => {

    let baseURL = BASE_URL + `substation-details`;
    try {
      const response = await axios.get(baseURL, getToken());
      let substationData = response?.data;
      let formatedSubData = substationData.map((v, i) => ({
        fields: {
          operationArea: v?.operationArea,
          substationName: v?.name,
          substationCode: v?.substationId,
          substationType: v?.type,
          coordinates: v?.coordinates,
          action: (
            <>
              <Button
                type="discreet-blue"
                onClick={() => {
                  localStorage.setItem("substation", JSON.stringify(v));
                  setisSubEdit(true);
                }}
                text="Edit"
                shape={"pill"}
              />

              <Button
                href={
                  "data:text/plain;charset=utf-8," +
                  encodeURIComponent("some text here")
                }
                type="discreet-blue"
                text="Delete"
                shape={"pill"}
                disabled={v?.substationId === "BAY-DS-8451"}
                onClick={() => openDeleteDialog(v?.substationId)}
              />
            </>
          ),
        },
      }));
      setSubData(formatedSubData);
      setispin(false);
    } catch (error) {

    }
  };

  useEffect(() => {
    fetchSubstationData();
  }, [isSubEdit]);

  const goToSettings = () => {
    navigate(-1);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setIsUploaded(true);
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64 = event.target.result.split(",")[1]; // Extract the base64 part

        setBase64String(base64);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleFileUpload = () => {

    const headers = {
      'Content-Type': 'application/pdf',
      'Authorization': getToken().headers.Authorization, // Include the token in the Authorization header
    };

    const url = BASE_URL + `substation-details-upload`;

    fetch(url, {
      method: "POST",
      body: base64String,
      headers: headers
    })
      .then((response) => {
        if (response.ok) {
          setisnotify("sub-success");
          fetchSubstationData();
          setIsUploaded(false);

          handleFileUploadCancel();
        } else {
          setisnotify("sub-failure");
          console.error("Failed to upload and post the file content.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleFileUploadCancel = () => {
    setIsUploaded(false);
    const fileInput = document.querySelector('input[type="file"]');
    fileInput.value = "";
    setBase64String("");
  };

  const fileName = "SubstationMappingSample.xlsx";
  const fileUrl = `${process.env.PUBLIC_URL}/${fileName}`;
  return (
    <>
      {isnotify === "remove-user" ? (
        <NotificationExample notitype={"remove-user"} />
      ) : (
        ""
      )}
      {isnotify === "sub-success" ? (
        <NotificationExample notitype={isnotify} />
      ) : (
        ""
      )}
      {isnotify === "sub-failure" ? (
        <NotificationExample notitype={isnotify} />
      ) : (
        ""
      )}
      {isnotify === "sub-delete" ? (
        <NotificationExample notitype={isnotify} />
      ) : (
        ""
      )}
      {isEdit || isSubEdit ? (
        <>
          {isSubEdit ? (
            <EditSubstation editfunc={setisSubEdit} />
          ) : (
            <EditUser />
          )}
        </>
      ) : (
        <div className="settings-page-content">
          <div className="row">
            <span className="go-back" onClick={goToSettings}>
              <Icon sizeClass="small" name="abb/left-arrow" />
              {"  "}Back
            </span>
          </div>
          <div className="row">
            <TabControl
              style={{ margin: "0px 16px" }}
              containerId="settings-tab-container"
              activeTab={activeTab}
              onTabChange={(oldIndex, newIndex) => setActiveTab(newIndex)}
            >
              <TabItem title="Manage Users">
                <div className="manage-user-container">
                  {isSpin ? (
                    <Spinner
                      style={{ margin: "auto" }}
                      color="dark-grey"
                      sizeClass="large"
                    />
                  ) : (
                    <>
                      <p className="get-user-profiles">Manage Users</p>

                      <Datagrid
                        className="user-profiles-table"
                        data={data}
                        columns={data?.length > 0 ? columns : ""}
                        rowHeight="small"
                        enableSorting={true}
                      />
                    </>
                  )}
                </div>
                {isUserDelete && (
                  <Dialog
                    className="save-confirm-dialog-container"
                    title={"Are you sure you want to remove this user?"}
                    showCloseButton={true}
                    closeOnEscape={true}
                    dimBackground={true}
                    standardButtonsOnBottom={[
                      {
                        text: "No",
                        type: "nornal",
                        handler: () => setisUserDelete(false),
                      },
                      {
                        text: "Yes",
                        type: "primary-blue",
                        handler: () => handleUserDelete(),
                      },
                    ]}
                  ></Dialog>
                )}
              </TabItem>
              <TabItem title="Substation Management">
                {isnotify === "sub-success" ? (
                  <NotificationExample notitype={isnotify} />
                ) : (
                  ""
                )}
                {isnotify === "sub-failure" ? (
                  <NotificationExample notitype={isnotify} />
                ) : (
                  ""
                )}
                {isDeleteDialogOpen && (
                  <Dialog
                    className="save-confirm-dialog-container"
                    showCloseButton={true}
                    closeOnEscape={true}
                    closeOnLostFocus={true}
                    hideBackground={true}
                    title="Are you sure you want to delete this substation?"
                    standardButtonsOnBottom={[
                      {
                        text: "No",
                        type: "discreet-blue",
                        handler: () => setIsDeleteDialogOpen(false),
                      },
                      {
                        text: "Yes",
                        type: "primary-blue",
                        handler: () => handleSubstationDelete(),
                      },
                      //(dlg) => setConfirmDRAck(false) && setDrAckStatus("Acknowledged") },
                    ]}
                  ></Dialog>
                )}
                <div className="manage-substaion-container">
                  <p className="get-user-profiles">Manage Substations</p>
                  <h6 className="manage-sub-title">
                    Upload Substation Details
                  </h6>
                  <input
                    className="manage-sub-file"
                    type="file"
                    label="Choose File"
                    accept=".xlsx, .xls"
                    onChange={handleFileChange}
                  />
                  <div className="manage-sub-buttons">
                    {isUploaded ? (
                      <>
                        <Button
                          style={{ marginRight: "12px" }}
                          text="Upload"
                          shape="rounded"
                          sizeClass={"small"}
                          type={"primary-black"}
                          onClick={handleFileUpload}
                        />

                        <Button
                          text="Clear"
                          shape="rounded"
                          sizeClass="small"
                          onClick={handleFileUploadCancel}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <a
                    href={fileUrl}
                    download={fileName}
                    className="manage-sub-download"
                  >
                    Download sample template
                  </a>
                  {isSpin ? (
                    <Spinner
                      style={{ margin: "auto" }}
                      color="dark-grey"
                      sizeClass="large"
                    />
                  ) : (
                    <>
                      <h5 style={{ margin: "32px 0px 16px" }}>
                        Substation List
                      </h5>
                      <Datagrid
                        className="substation-table"
                        data={subData}
                        columns={subData.length > 0 ? subcolumns : ""}
                        rowHeight="small"
                        fitToContent={true}
                        sortOrder={{
                          field: "operationArea",
                          desc: true,
                        }}
                      />
                    </>
                  )}
                </div>
                {/* <ManageSubstation/> */}
              </TabItem>
            </TabControl>
          </div>
          <div>
            <div id="settings-tab-container" />
          </div>
        </div>
      )}
    </>
  );
};

export default ManageUsers;
