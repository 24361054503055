import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  Link,
  DropdownOption,
  Dropdown,
} from "@abb/abb-common-ux-react";
import { Auth, Amplify } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { MESSAGES, SUBSTATION_ROLES } from "../../strings";
import { awsConfig } from "../../components/Authentication/aws-config";
import "./signup.css";

function Signup() {
  Amplify.configure(awsConfig);
  const navigate = useNavigate();
  const [selectedRole, setselectedRole] = React.useState(
    SUBSTATION_ROLES[0].value
  );
  const [displayRole, setdisplayRole] = React.useState(SUBSTATION_ROLES[0]);
  const [showPostSignupSteps, setshowPostSignupSteps] = useState(false);
  const [vCode, setvCode] = React.useState("");
  const [userExists, setuserExists] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [state, setState] = useState({
    firstname: "",
    lastname: "",
    currentlocation: "",
    role: "",
    email: "",
    password: "",
    confirmpassword: "",
    phoneNumber: "",
  });
  const [formValues, setFormValues] = useState({
    firstname: "",
    lastname: "",
    currentlocation: "",
    role: "",
    email: "",
    password: "",
    confirmpassword: "",
    phoneNumber: "",
  });
  const { firstname, lastname, email, currentlocation, password, phoneNumber } =
    state;
  useEffect(() => { }, [userExists]);

  useEffect(() => { }, [showPostSignupSteps]);

  const handleChange = (e) => {
    setState({
      ...state,
      role: selectedRole,
      [e?.target?.id]: e?.target?.value,
    });
    setFormValues({ ...formValues, [e?.target?.id]: e?.target?.value });
  };

  function validatePhoneNumber(phoneNumber) {
    // Remove any non-digit characters from the phone number
    const cleanNumber = phoneNumber.replace(/\D/g, '');

    // Check if the cleaned number is between 10 and 15 digits
    if (cleanNumber.length >= 10 && cleanNumber.length <= 15) {
      return true; // Valid phone number
    } else {
      return false; // Invalid phone number
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const errors = {};

    if (!formValues.firstname) {
      errors.firstname = "Firstname is required.";
    }
    if (!formValues.lastname) {
      errors.lastname = "Lastname is required.";
    }
    if (!formValues.currentlocation) {
      errors.currentlocation = "Current Location is required.";
    }

    if (!formValues.email) {
      errors.email = "Email is required.";
    }
    if (!formValues.password) {
      errors.email = "Password is required.";
    }
    if (!formValues.confirmpassword) {
      errors.confirmpassword = "Confirm password is required.";
    }
    if (!formValues.phoneNumber) {
      errors.phoneNumber = "Phone number is required.";
    }
    if (!validatePhoneNumber(formValues.phoneNumber)) {
      errors.phoneNumber = "Enter valid phone number.";
    }

    if (formValues.password.length < 8) {
      errors.password = `Password must be minimum 8 characters.`;
    }
    if (formValues.password !== formValues.confirmpassword) {
      errors.confirmpassword =
        "Confirm password must be match with the password";
    }
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      try {
        const response = await Auth.signUp({
          username: email,
          password,
          attributes: {
            "custom:currentlocation": currentlocation,
            "custom:firstname": firstname,
            "custom:lastname": lastname,
            "custom:phoneNumber": phoneNumber,
            "custom:role": selectedRole,
          },
        });
        setshowPostSignupSteps(true);
        setuserExists("");
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setuserExists(error.toString().split(":")[1]);
      }
    } else {
      setLoading(false);
    }
  };
  const goToLogin = () => {
    navigate("../");
  };
  const verifyCode = async () => {
    try {
      const response = await Auth.confirmSignUp(email, vCode);
    } catch (error) {
      // Some Errors
      // {code: "CodeMismatchException", name: "CodeMismatchException", message: "Invalid verification code provided, please try again."}
    }
  };
  const resendCode = async () => {
    try {
      await Auth.resendSignUp(email);
    } catch (error) { }
  };

  return (
    <>
      {showPostSignupSteps && (
        <>
          <div class="ReactModal__Overlay ReactModal__Overlay--after-open ABB_CommonUX_Dialog__overlay ABB_CommonUX_Dialog__halfOpacityBackdrop ABB_CommonUX_Dialog__light">
            <Dialog
              className="signup-dialog-container"
              title={MESSAGES.POST_SIGN_WAITING}
            >
              <p>{MESSAGES.POST_SIGN_UP_TRIGGER}</p>

              <Button
                sizeClass={"medium"}
                text="OK"
                type="primary-blue"
                onClick={goToLogin}
              />
            </Dialog>
          </div>
        </>
      )}
      <div className="ABB_CommonUX_LoginScreen__root ABB_CommonUX_LoginScreen__light signup-container-box">
        <div className="ABB_CommonUX_LoginScreen__header">
          <div />
          <div className="ABB_CommonUX_LoginScreen__logo abb-signup-header" />
        </div>
        <div className="ABB_CommonUX_LoginScreen__contentWrapper">
          <div className="ABB_CommonUX_LoginScreen__content">
            <form id="submitSignupform" onSubmit={handleSubmit}>
              <h1 className="ABB_CommonUX_LoginScreen__productName">Sign up</h1>
              {loading ? (
                <p style={{ color: "green" }}>{MESSAGES.IN_SIGNUP_TRIGGER}</p>
              ) : (
                ""
              )}

              <div className="ABB_CommonUX_LoginScreen__form">
                <p style={{ color: "red" }}> {userExists} </p>
                <div className="ABB_CommonUX_LoginScreen__formRow">
                  <div className="ABB_CommonUX_LoginScreen__customContent">
                    <div />
                  </div>
                </div>
                <div className="ABB_CommonUX_LoginScreen__formRow signup-container">
                  <div className="section-1">
                    <div>
                      <div className="ABB_CommonUX_Input__root ABB_CommonUX_Input__light ABB_CommonUX_Input__normal ABB_CommonUX_Input__isValid ABB_CommonUX_Input__isEmpty">
                        <div className="ABB_CommonUX_Input__labelContainer">
                          <span className="ABB_CommonUX_Input__label">
                            First name
                          </span>
                        </div>
                        <div className="ABB_CommonUX_Input__inputWrapper ABB_CommonUX_Input__hasFixedSize">
                          <input
                            className="ABB_CommonUX_Input__inputElement"
                            type="text"
                            autoComplete="firstname"
                            defaultValue=""
                            required
                            name="firstname"
                            id="firstname"
                            onChange={handleChange}
                          />
                          <span className="ABB_CommonUX_Input__iconWrapper">
                            <i className="ABB_CommonUX_Icon__root">
                              <i className="ABB_CommonUX_Icon__icon_abb_16 ABB_CommonUX_Icon-16-style__icon-abb_user_16" />
                            </i>
                          </span>
                        </div>
                      </div>
                      {formErrors.firstname && (
                        <span className="error-display">
                          {formErrors.firstname}
                        </span>
                      )}
                    </div>
                    <div>
                      <div className="ABB_CommonUX_Input__root ABB_CommonUX_Input__light ABB_CommonUX_Input__normal ABB_CommonUX_Input__isValid ABB_CommonUX_Input__isEmpty">
                        <div className="ABB_CommonUX_Input__labelContainer">
                          <span className="ABB_CommonUX_Input__label">
                            Last name
                          </span>
                        </div>
                        <div className="ABB_CommonUX_Input__inputWrapper ABB_CommonUX_Input__hasFixedSize">
                          <input
                            className="ABB_CommonUX_Input__inputElement"
                            type="text"
                            autoComplete="lastname"
                            defaultValue=""
                            required
                            name="lastname"
                            id="lastname"
                            onChange={handleChange}
                          />
                          <span className="ABB_CommonUX_Input__iconWrapper">
                            <i className="ABB_CommonUX_Icon__root">
                              <i className="ABB_CommonUX_Icon__icon_abb_16 ABB_CommonUX_Icon-16-style__icon-abb_user_16" />
                            </i>
                          </span>
                        </div>
                      </div>
                      {formErrors.lastname && (
                        <span className="error-display">
                          {formErrors.lastname}
                        </span>
                      )}
                    </div>

                    <div className="ABB_CommonUX_Input__root ABB_CommonUX_Input__light ABB_CommonUX_Input__normal ABB_CommonUX_Input__isValid ABB_CommonUX_Input__isEmpty">
                      <div className="ABB_CommonUX_Input__labelContainer">
                        <span className="ABB_CommonUX_Input__label">
                          Current Location
                        </span>
                      </div>
                      <div className="ABB_CommonUX_Input__inputWrapper ABB_CommonUX_Input__hasFixedSize">
                        <input
                          className="ABB_CommonUX_Input__inputElement"
                          type="text"
                          autoComplete="current location"
                          defaultValue=""
                          required
                          name="currentlocation"
                          id="currentlocation"
                          onChange={handleChange}
                        />
                        <span className="ABB_CommonUX_Input__iconWrapper">
                          <i className="ABB_CommonUX_Icon__root">
                            <i className="ABB_CommonUX_Icon__icon_abb_16 ABB_CommonUX_Icon-16-style__icon-abb_location_16" />
                          </i>
                        </span>
                      </div>
                      {formErrors.currentlocation && (
                        <span className="error-display">
                          {formErrors.currentlocation}
                        </span>
                      )}
                    </div>

                    <div>
                      <Dropdown
                        className="drop-ops-area"
                        label="Role"
                        id="role"
                        placeholder={displayRole}
                        name="role"
                        defaultValue={displayRole}
                        value={displayRole}
                        onChange={(val) => {
                          setselectedRole(val[0].value);
                          setdisplayRole(val[0].label);
                          handleChange(val[0].value);
                        }}
                      >
                        {SUBSTATION_ROLES.map((o) => (
                          <DropdownOption
                            key={o.label}
                            label={o.label}
                            value={o.value}
                          />
                        ))}
                      </Dropdown>
                      {/* {formErrors.selectedRole && <span className="error-display">{formErrors.selectedRole}</span>} */}
                    </div>
                  </div>

                  <div className="section-2" style={{ paddingLeft: "40px" }}>
                    <div className="ABB_CommonUX_Input__root ABB_CommonUX_Input__light ABB_CommonUX_Input__normal ABB_CommonUX_Input__isValid ABB_CommonUX_Input__isEmpty">
                      <div className="ABB_CommonUX_Input__labelContainer">
                        <span className="ABB_CommonUX_Input__label">Email</span>
                      </div>
                      <div className="ABB_CommonUX_Input__inputWrapper ABB_CommonUX_Input__hasFixedSize">
                        <input
                          className="ABB_CommonUX_Input__inputElement"
                          type="text"
                          autoComplete="email"
                          defaultValue=""
                          required
                          name="email"
                          id="email"
                          onChange={handleChange}
                        />
                        <span className="ABB_CommonUX_Input__iconWrapper">
                          <i className="ABB_CommonUX_Icon__root">
                            <i className="ABB_CommonUX_Icon__icon_abb_16 ABB_CommonUX_Icon-16-style__icon-abb_envelope_16" />
                          </i>
                        </span>
                      </div>

                      {formErrors.email && (
                        <span className="error-display">
                          {formErrors.email}
                        </span>
                      )}
                    </div>
                    <div className="ABB_CommonUX_LoginScreen__password">
                      <div className="ABB_CommonUX_Input__root ABB_CommonUX_Input__light ABB_CommonUX_Input__normal ABB_CommonUX_Input__isValid ABB_CommonUX_Input__isEmpty">
                        <div className="ABB_CommonUX_Input__labelContainer">
                          <span className="ABB_CommonUX_Input__label">
                            Password
                          </span>
                        </div>
                        <div className="ABB_CommonUX_Input__inputWrapper ABB_CommonUX_Input__hasFixedSize">
                          <input
                            className="ABB_CommonUX_Input__inputElement"
                            type="password"
                            autoComplete="current-password"
                            defaultValue=""
                            required
                            name="password"
                            id="password"
                            onChange={handleChange}
                          />
                          <span className="ABB_CommonUX_Input__iconWrapper">
                            <i className="ABB_CommonUX_Icon__root">
                              <i className="ABB_CommonUX_Icon__icon_abb_16 ABB_CommonUX_Icon-16-style__icon-abb_lock-closed_16" />
                            </i>
                          </span>
                        </div>
                        {formErrors.password && (
                          <span className="error-display">
                            {formErrors.password}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="ABB_CommonUX_Input__root ABB_CommonUX_Input__light ABB_CommonUX_Input__normal ABB_CommonUX_Input__isValid ABB_CommonUX_Input__isEmpty">
                      <div className="ABB_CommonUX_Input__labelContainer">
                        <span className="ABB_CommonUX_Input__label">
                          Confirm Password
                        </span>
                      </div>
                      <div className="ABB_CommonUX_Input__inputWrapper ABB_CommonUX_Input__hasFixedSize">
                        <input
                          className="ABB_CommonUX_Input__inputElement"
                          type="password"
                          autoComplete="current-password"
                          defaultValue=""
                          required
                          name="confirmpassword"
                          id="confirmpassword"
                          onChange={handleChange}
                        />
                        <span className="ABB_CommonUX_Input__iconWrapper">
                          <i className="ABB_CommonUX_Icon__root">
                            <i className="ABB_CommonUX_Icon__icon_abb_16 ABB_CommonUX_Icon-16-style__icon-abb_lock-closed_16" />
                          </i>
                        </span>
                      </div>
                      {formErrors.confirmpassword && (
                        <span className="error-display">
                          {formErrors.confirmpassword}
                        </span>
                      )}
                    </div>
                    <div>
                      <div className="ABB_CommonUX_Input__root ABB_CommonUX_Input__light ABB_CommonUX_Input__normal ABB_CommonUX_Input__isValid ABB_CommonUX_Input__isEmpty">
                        <div className="ABB_CommonUX_Input__labelContainer">
                          <span className="ABB_CommonUX_Input__label">
                            Phone number
                          </span>
                        </div>
                        <div className="ABB_CommonUX_Input__inputWrapper ABB_CommonUX_Input__hasFixedSize">
                          <input
                            className="ABB_CommonUX_Input__inputElement"
                            type="text"
                            autoComplete="Phone Number"
                            defaultValue=""
                            required
                            name="phoneNumber"
                            maxLength={13}
                            id="phoneNumber"
                            onChange={handleChange}
                          />
                          <span className="ABB_CommonUX_Input__iconWrapper">
                            <i className="ABB_CommonUX_Icon__root">
                              <i className="ABB_CommonUX_Icon__icon_abb_16 ABB_CommonUX_Icon-16-style__icon-abb_call_16" />
                            </i>
                          </span>
                        </div>
                      </div>
                      {formErrors.phoneNumber && (
                        <span className="error-display">
                          {formErrors.phoneNumber}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="ABB_CommonUX_LoginScreen__bottom">
                  <div className="ABB_CommonUX_LoginScreen__buttons ABB_CommonUX_LoginScreen__flexSpring">
                    <div className="ABB_CommonUX_LoginScreen__button">
                      <button className="ABB_CommonUX_Button__root ABB_CommonUX_Button__primaryblack ABB_CommonUX_Button__mediumSize ABB_CommonUX_Button__light ABB_CommonUX_Button__rounded">
                        <div>
                          <span className="ABB_CommonUX_Button__text ABB_CommonUX_Button__hasText">
                            SignUp &nbsp;
                          </span>
                        </div>
                      </button>
                    </div>
                    <span style={{ paddingTop: "5px" }}>
                      {" "}
                      Already have account?{" "}
                      <Link onClick={goToLogin}>Sign in</Link>
                    </span>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* <div className="ABB_CommonUX_LoginScreen__footer">
                <div>
                    This is a private system. Do not attempt to logon unless you are an
                    authorized user. Any authorized or unauthorized access and use may be
                    monitored and can result in criminal or civil prosecution under applicable
                    law.
                </div>
            </div> */}
      </div>
    </>
  );
}
export default Signup;
