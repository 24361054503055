import React, { useEffect, useState, useContext, useMemo } from "react";
import {
  Dropdown,
  DropdownOption,
  Icon,
  Input,
  Button,
  Datagrid,
  Spinner,
  Link,
} from "@abb/abb-common-ux-react";
import { RootContext } from "../../contexts/root-context";
import axios from "axios";
import NotificationExample from "../Notification/notification";
import { BASE_URL, OPERATION_AREA } from "../../strings";
import { convertUtcToLocalString, decryptData } from "../../util";
import { getToken } from "../Authentication/get-token";
import "./historical-reports.css";

const HistoricalReports = (props) => {
  const id = "BAY-DS-8451";
  const { isnotify, setisnotify } = useContext(RootContext);
  const [startdate, setstartDate] = useState("");
  const [enddate, setenddate] = useState("");
  const [hrData, setHrData] = useState([]);
  const [isSpin, setispin] = useState(true);
  const [isRelaySpin, setIsRelaySpin] = useState(true);
  const [generateReportCaption, setgenerateReportCaption] = useState("Generate Report");
  const [disableReportBtn, setdisableReportBtn] = useState(true);
  const [options, setOptions] = useState([]);
  const [selectedMulti, setSelectedMulti] = useState([]);
  const initialColumns = useMemo(
    () => [
      { fieldKey: "reportName", title: "REPORT NAME" },
      { fieldKey: "requestedOn", title: "Requested On" },
      { fieldKey: "status", title: "Status" },
      { fieldKey: "action", title: "ACTION" },
    ],
    []
  );
  const SUBSTATION_ID = localStorage.getItem("selectedSubstationId");
  const [columns, setColumns] = useState(initialColumns);

  const commonFlags = {
    clearable: true,
    searchable: true,
    clearOnEscape: true,
    sizeClass: "small",
  };
  const fetchRelays = async () => {
    try {
      var historicalReportUrl =
        BASE_URL + `historical-reports-relays?substationId=${SUBSTATION_ID}`;
      const response = await axios.get(historicalReportUrl, getToken());
      if (response?.data) {
        setOptions(response.data);
      } else {
        console.error("Response data is not available.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const fetchHRData = async () => {
    try {
      const response = await axios.get(
        BASE_URL + `historical-reports-list?substationId=${SUBSTATION_ID}`, getToken()
      );
      const responseData = response?.data;
      // Map and format the data
      const formattedData = responseData?.map((v) => ({
        fields: {
          reportName: v?.name,
          requestedOn: (v?.requestedOn),
          status: v?.status,
          action: (
            <Button
              onClick={() => downloadHR(v.name)}
              download={v.name + ".zip"}
              sizeClass={"small"}
              type="discreet-blue"
              text="download"
              shape={"pill"}
              disabled={v?.status !== 'Ready'}
            />
          ),
        },
      }));
      // Update the state with the formatted data
      setHrData(formattedData);

      setIsRelaySpin(false);
      setispin(false);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const downloadHR = async (name) => {
    const res = await axios.get(
      BASE_URL + `historical-reports-download?fileName=${name}`, getToken()
    );
    const link = res.data.url;
    window.open(link);
  };

  useEffect(() => {
    fetchHRData();
    fetchRelays();
  }, []);

  useEffect(() => {
    validategenerateReport();
    dateValidate();
  }, [selectedMulti, startdate, enddate, generateReportCaption]);

  const validateEndDate = (date) => {
    if (date > convertUtcToLocalString(new Date())) {
      //setdisableReportBtn(true);
      return false
    }
    return true;
  }

  const validateStartDate = (date) => {
    const currentDate = convertUtcToLocalString(new Date());
    const threeMonthsAgo = convertUtcToLocalString(getThreeMonthsAgoDate());

    if (date > currentDate || date > enddate || date < threeMonthsAgo) {
      return false;
    }
    return true;
  };

  const getThreeMonthsAgoDate = () => {
    const currentDate = new Date();
    const threeMonthsAgo = new Date(currentDate);
    threeMonthsAgo.setMonth(currentDate.getMonth() - 3);
    return threeMonthsAgo;
  };

  function dateValidate() {
    if (startdate && startdate < convertUtcToLocalString(getThreeMonthsAgoDate())) {
      return "Start date cannot be older than 3 months.";
    } else if (startdate > new Date()) {
      return "Start date cannot be a future date";
    } else if (enddate > new Date()) {
      return "End date cannot be a future date";
    } else if (startdate && enddate && startdate > enddate) {
      return "Start date cannot be after End date";
    } else {
      return "";
    }
  }

  const validategenerateReport = () => {
    if (selectedMulti.length >= 1 && startdate && enddate && validateEndDate(enddate) && validateStartDate(startdate)) {
      setdisableReportBtn(false);
    } else {
      setdisableReportBtn(true);
    }
  };



  function formatDateTime(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }

  const handlegenerateReport = async () => {
    if (selectedMulti.length >= 1 && startdate && enddate) {
      setdisableReportBtn(true);
      setisnotify("genrateReport");
      //setgenerateReportCaption("Generating...");
      setTimeout(() => {
        setgenerateReportCaption("Generate Report");
        setisnotify("");
        setenddate("");
        setstartDate("");
      }, 3000);
      const relays = selectedMulti.map((x) => x.value).join(",");

      try {
        let userId = decryptData(localStorage.getItem('logged_in_user'))?.replace(/"/g, '');
        let substationName = localStorage.getItem("seletectedSubstation");
        const res = await axios.get(
          BASE_URL +
          `historical-reports-genarator?startDate=${startdate}T00:00&endDate=${enddate}T23:59&substationId=${id}` +
          `&relays=${relays}&userId=${userId}&substationName=${substationName}&operationArea=${OPERATION_AREA}&` +
          `requestedOn=${formatDateTime(new Date())}`, getToken()
        );

        if (res.status === 200) {

          if (res.data === 'NO-DR') {
            setisnotify("nodr");
          } else {
            setisnotify("hr");
          }
          setTimeout(() => {
            setgenerateReportCaption("Generate Report");
            setisnotify("");
            setenddate("");
            setstartDate("");
          }, 3000);
          fetchHRData();
        }
      } catch (error) {

      }
    } else {
      setdisableReportBtn(true);
    }
  };
  return (
    <>
      {isnotify === "genrateReport" ? (
        <NotificationExample notitype={isnotify} />
      ) : (
        ""
      )}
      {isnotify === "hr" ? (
        <NotificationExample notitype={isnotify} />
      ) : (
        ""
      )}
      {isnotify === "nodr" ? (
        <NotificationExample notitype={isnotify} />
      ) : (
        ""
      )}
      <h6 style={{ textAlign: "left", color: "black" }}>
        Generate Historical DR Reports
      </h6>
      <table id="sld-info-table">
        <tr>
          <td className="first-col">
            <b>Substation Name :</b> <br />
            <span>{localStorage.getItem("seletectedSubstation")}</span>
            <br />
          </td>
        </tr>
        <tr>
          <td className="first-col">
            <b>Relay :</b>
            <br />
            <span>
              {isRelaySpin ? (
                <Spinner
                  style={{ margin: "auto" }}
                  color="dark-grey"
                  sizeClass="small"
                />
              ) : (<>
                <Dropdown
                  style={{
                    flex: "1",
                    minWidth: "160px",
                    width: "600px",
                    maxWidth: "600px",
                  }}
                  placeholder="Pick many"
                  monochrome={false}
                  multiselect={true}
                  value={selectedMulti}
                  onChange={(val) => {
                    setSelectedMulti(val);

                  }}
                  initialOpen="multi"
                  {...commonFlags}
                >
                  {options?.map((o, i) => (
                    <DropdownOption key={i} label={o.relay} value={o.relay} />
                  ))}
                </Dropdown>{" "}</>)}
            </span>
          </td>
        </tr>
        <tr>
          <td className="first-col">
            <b>Time Frame :</b>
            <br />
            <span>
              Select the time frame for which you wish to generate DR files.
            </span>
            <span
              style={{
                display: "flex",
              }}
            >
              <Input
                style={{
                  flex: "1",
                  minWidth: "160px",
                  width: "600px",
                  maxWidth: "300px",
                }}
                type="normal"
                dataType="date"
                label="Start Date"

                onValueChange={(val) => setstartDate(val)}
                showClearIcon={true}
                clearOnEscape={true}
                value={startdate}
                prefix={<Icon name="abb/calendar" />}
              />
              <Input
                style={{
                  flex: "1",
                  minWidth: "160px",
                  width: "300px",
                  maxWidth: "300px",
                  paddingLeft: "10px",
                }}
                type="normal"
                dataType="date"
                label="End Date"

                onValueChange={(val) => {
                  setenddate(val);
                }}
                showClearIcon={true}
                clearOnEscape={true}
                value={enddate}
                prefix={<Icon name="abb/calendar" />}
              />
            </span>
          </td>
        </tr>
        <tr>
          <td
            className="first-col"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Button
              style={{ padding: "12px", width: "150px" }}
              sizeClass={"small"}
              type={"primary-black"}
              disabled={disableReportBtn}
              shape={"pill"}
              text={generateReportCaption}
              onClick={() => {
                handlegenerateReport();
              }}
            />{" "}
            <p className="date-error">
              {dateValidate()}
            </p>
            <span style={{ paddingTop: "12px" }}>
              Note: Only data from the last three month is available for
              download.{" "}
            </span>
          </td>
        </tr>
      </table>
      <p className="generate-report-header">
        <b>Download Generated Report</b>
        <span className="hr-refresh">
          <Link
            discreet={true}
            iconBefore="abb/synchronize"
            onClick={fetchHRData}
            preventVisited={true}
            underlined={true}>
            {`Refresh`}
          </Link>
        </span>
      </p >
      {
        isSpin ? (
          <Spinner
            style={{ margin: "auto" }}
            color="dark-grey"
            sizeClass="large"
          />
        ) : (<>
          <Datagrid
            className="historical-report-table"
            data={hrData}
            columns={hrData.length > 0 ? columns : ""}
            rowHeight="small"
            fitToContent={true}
            sortOrder={{
              field: 'requestedOn',
              desc: true
            }}
          /></>)}
    </>
  );
};

export default HistoricalReports;
